import background from './../assets/img/background.jpg';
import bluemountains from './../assets/img/bluemountains.jpg';
import selfie from './../assets/img/selfie1cropped.png';
import cv from './../assets/pdf/cv-kaspar.pdf';
import './../assets/css/Home.css';

import { Grommet, Box, Text, Image, Button, Anchor } from 'grommet';
import { theme } from "./../theme";
import { useRef } from 'react';

import { Parallax, ParallaxLayer } from '@react-spring/parallax';

import {Github, Linkedin, Mail} from 'grommet-icons'

const Home = () => {
  const ref = useRef();

  return (
    <Grommet background='background' theme={theme}>
      <Box height='100vh' width='100%' background='background'>

        <Parallax pages={3} ref={ref}>

          <ParallaxLayer speed={1} onClick={() => ref.current.scrollTo(1)}>
            <Box direction='row' align='center' justify='center' height='100vh' gap='medium'>
              <Box pad='large' width='50vw'>
                <Text size='5xl'>Hi, I am Kaspar, a Web Developer</Text>
              </Box>

              <Box pad='xlarge' width='50vw' height='100vh'>
                <Image src={selfie} fit='cover' style={{'border-radius': '5%'}}/>
              </Box>
            </Box>
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={0.1} onClick={() => ref.current.scrollTo(2)}>
            <Box pad='large' width='100vw' height='100vh' gap='medium'>
              <Image src={bluemountains} fit='cover'/>
            </Box>
          </ParallaxLayer>
          
          <ParallaxLayer offset={1.3} speed={1} onClick={() => ref.current.scrollTo(2)}>
            <Box pad='large' width='100vw' justify='center' align='center'>
              <Text textAlign='center' size='5xl'>Also a Data Scientist, Musician & Traveler</Text>
            </Box>
          </ParallaxLayer>

          <ParallaxLayer offset={2} speed={1}>
            <Box pad='xlarge' height='100%' width='100%' align='center' justify='between' background='white'>
              <Box gap='medium'>
                <Text color='primary' textAlign='start' size='large'>I am a 24 years old, ambitious software engineer. I just finished my bachelor software engineering at the Amsterdam University of Applied Sciences. I am a confident web developer and last years I have been deepening into data science. I love to work with data and developing dashboards around findings. I am able to work independent or collaborate in a team. In my free time I love to make music (Flamenco, Spanish guitar), traveling or be social with friends.</Text>
                <Box alignSelf='center' height='50px' width='300px'>
                  <Button primary onClick={() => window.location.href=cv} fill label={<Text justify='center' weight="400" size='large'>VIEW MY RESUME</Text>}></Button>
                </Box>
              </Box>

              <Box direction='row' gap='small'>
                <Anchor href='https://github.com/kasparpollet'>
                  <Github color='primary'></Github>
                </Anchor>
                <Anchor href='https://www.linkedin.com/in/kaspar-pollet-622991216/'>
                  <Linkedin color='primary'></Linkedin>
                </Anchor>
                <Anchor href='mailto:kasparpollet127@gmail.com'>
                  <Mail color='primary'></Mail>
                </Anchor>
              </Box>
            </Box>
          </ParallaxLayer>

          {/* add sticky layer for back up button */}
          
          {/* <ParallaxLayer sticky={{ start: 1, end: 3 }} class='sticky'>
            <Box pad='small' align='end' justify='end' alignSelf='end'>
              <Upgrade size='large' onClick={() => ref.current.scrollTo(0)}></Upgrade>
            </Box>
          </ParallaxLayer> */}

        </Parallax>
      </Box>
    </Grommet>

  );
}

export default Home;
