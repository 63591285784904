export const theme = {
    global: {
      colors: {
        brand: '#282c34',
        white: "#FAF9F6",
        black: "#000000",
        text: "#FAF9F6",
        background: "#282c34",
        primary: "#282c34",
      },
      font: {
        family: "SoinSansNeue",
        size: "100%",
      },
      active: {
        color: 'primary'
      }
    },

    button: {
      extend: ({ primary }) => {
        if (primary) {
          return `
          &:hover {
            background: ${"#FAF9F6"};
            color: ${"#282c34"};
          }
        `;
        }
      }
    }
  };
  